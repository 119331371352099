import Link from 'next/link'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import type { Theme } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import RichTextList from '../RichTextList'
import { StyledWrappedLink } from '../StyledWrappedLink'
import Accordion from './Accordion'
import type { AccordionEntryProps, ContentAccordionProps } from './Accordion.types'

const richTextRenderOptions = (theme: Theme) => ({
  renderNode: {
    [BLOCKS.OL_LIST]: (_node, children) => <RichTextList>{children}</RichTextList>,
    [BLOCKS.UL_LIST]: (_node, children) => <RichTextList>{children}</RichTextList>,
    [BLOCKS.HEADING_5]: (_node, children) => (
      <Typography
        variant="h5"
        data-cy="accordion-entry-text-headline-five"
        sx={{
          color: theme.palette.text.primary,
          marginBottom: '16px',
          scrollMarginTop: 'inherit',
        }}
      >
        {children}
      </Typography>
    ),
    [INLINES.ENTRY_HYPERLINK]: ({ data }, children) => (
      <StyledWrappedLink
        prefetch={false}
        styledLinkReference={data.target}
        analyticsComponentName="content_accordion"
        nextLinkComponent={Link}
        dataCy="accordion-entry-link"
        endIcon=""
        underline="always"
        variant="body2"
        sx={{ padding: 0, lineHeight: '22px' }} // lineHeight should follow paragrapth text one
      >
        {children}
      </StyledWrappedLink>
    ),
  },
})

const AccordionWrapper: React.FC<ContentAccordionProps> = ({ fields }) => {
  const theme = useTheme()

  const accordionList: AccordionEntryProps[] = fields?.accordionEntries
    ?.filter((accordion) => accordion.fields) // Filter out draft content from contentful
    .map((entry) => {
      const { fields: entryFields } = entry

      return {
        id: entry.sys.id,
        headline: entryFields?.headline || '',
        body: documentToReactComponents(entryFields?.text, richTextRenderOptions(theme)),
      }
    })

  return <Accordion headline={fields.headline} accordionEntries={accordionList || []} />
}

export default AccordionWrapper
