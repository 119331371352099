import { WEB_CONTAINER_VERTICAL_SPACE } from '@knauf-group/ct-designs/themeConfigs/constants'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import type { AccordionProps } from './Accordion.types'
import SingleAccordion from './SingleAccordion'

const Accordion = ({
  headline,
  accordionEntries,
  dataCy = 'accordion',
}: Partial<AccordionProps>) => {
  const theme = useTheme()
  return (
    <Box data-cy={dataCy} sx={WEB_CONTAINER_VERTICAL_SPACE}>
      <Grid container display="flex" justifyContent="center">
        <Grid item lg={8}>
          <Typography
            variant="h2"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: '40px',
              textAlign: 'center',
              padding: '0 48px',
            }}
            data-cy={`${dataCy}-headline`}
          >
            {headline}
          </Typography>
        </Grid>
        {accordionEntries.map((entry, index) => {
          const showDivider =
            accordionEntries.length > 1 && index !== accordionEntries.length - 1
          return (
            <Grid item xs={12} md={8} key={entry.id} data-cy={`${dataCy}-entry`}>
              <SingleAccordion headline={entry.headline} body={entry.body} dataCy={dataCy} />
              {showDivider && <Divider sx={{ borderColor: theme.palette.divider }} />}
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default Accordion
