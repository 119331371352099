import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import type { AccordionEntryProps } from './Accordion.types'

const SingleAccordion = ({ headline, body, dataCy }: Partial<AccordionEntryProps>) => {
  const theme = useTheme()
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={
          <IconButton>
            <ExpandMoreIcon />
          </IconButton>
        }
      >
        <Box component="span">
          <Typography variant="body2" data-cy={`${dataCy}-entry-heading`}>
            {headline}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          data-cy={`${dataCy}-entry-content`}
          component="div"
        >
          {body}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default SingleAccordion
